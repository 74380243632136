<template>
  <div class="page"></div>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>
<style lang="less">
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-left-10 {
  margin-left: 10px;
}

.margin-left-5 {
  margin-left: 5px;
}

.color-red {
  color: red;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-22 {
  font-size: 22px;
}
.font-blod {
  font-weight: bold;
}
.justify-content {
  display: flex;
  justify-content: center;
}
html {
  background-color: #e6e6e6;
}
</style>
