import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () =>
      import('../views/index.vue'),
    redirect: "/lock/home"
  },

  {
    path: '/site',
    name: 'site',
    component: () =>
      import('../views/index.vue'),
    redirect: "/site/home",
    children: [
      {
        path: '/site/home',
        name: 'site_home',
        component: () =>
          import('../views/site/Home.vue'),
      },
      {
        path: '/site/service',
        name: 'site_service',
        component: () =>
          import('../views/site/service.vue'),
      },
      {
        path: '/site/pay',
        name: 'site_pay',
        component: () =>
          import('../views/site/service2.vue'),
      },
      {
        path: '/site/success',
        name: 'site_success',
        component: () =>
          import('../views/site/success.vue'),
      },
      {
        path: '/site/error',
        name: 'site_error',
        component: () =>
          import('../views/site/error.vue'),
      }
    ]
  },
  // 智能锁1
  {
    path: '/lock',
    name: 'lock',
    component: () =>
      import('../views/index.vue'),
    redirect: "/lock/home",
    children: [
      {
        path: '/lock/home',
        name: 'lock_home',
        component: () =>
          import('../views/lock/Home.vue'),
      },
      {
        path: '/lock/service',
        name: 'lock_service',
        component: () =>
          import('../views/lock/service.vue'),
      },
      {
        path: '/lock/success',
        name: 'lock_success',
        component: () =>
          import('../views/lock/success.vue'),
      },
      {
        path: '/lock/error',
        name: 'lock_error',
        component: () =>
          import('../views/lock/error.vue'),
      },
      {
        path: '/lock/weChatWrok',
        name: 'lock_weChatWrok',
        component: () =>
          import('../views/weChatWork/index.vue'),
      }, {
        path: '/lock/weChatWrok2',
        name: 'lock_weChatWrok2',
        component: () =>
          import('../views/weChatWork/index2.vue'),
      }
    ]
  },
  // 智能锁2
  {
    path: '/lock2',
    name: 'lock2',
    component: () =>
      import('../views/index.vue'),
    redirect: "/lock2/home",
    children: [
      {
        path: '/lock2/home',
        name: 'lock2_home',
        component: () =>
          import('../views/lock2/Home.vue'),
      },
      {
        path: '/lock2/service',
        name: 'lock2_service',
        component: () =>
          import('../views/lock2/service.vue'),
      },
      {
        path: '/lock2/success',
        name: 'lock2_success',
        component: () =>
          import('../views/lock2/success.vue'),
      },
      {
        path: '/lock2/error',
        name: 'llock2_error',
        component: () =>
          import('../views/lock2/error.vue'),
      },

    ]
  },
  // 智能锁3
  {
    path: '/lock3',
    name: 'lock3',
    component: () =>
      import('../views/index.vue'),
    redirect: "/lock3/home",
    children: [
      {
        path: '/lock3/home',
        name: 'lock3',
        component: () =>
          import('../views/lock3/Home.vue'),
      },
      {
        path: '/lock3/service',
        name: 'lock3_service',
        component: () =>
          import('../views/lock3/service.vue'),
      },
      {
        path: '/lock3/success',
        name: 'lock3_success',
        component: () =>
          import('../views/lock3/success.vue'),
      },
      {
        path: '/lock3/error',
        name: 'lock3_error',
        component: () =>
          import('../views/lock3/error.vue'),
      },

    ]
  },
  // 其他页面
  {
    path: '/other',
    name: 'other',
    component: () =>
      import('../views/index.vue'),
    redirect: "/other/donation1",
    children: [
      {
        path: '/other/donation1',
        name: 'donation1',
        component: () =>
          import('../views/other/donation1.vue'),
      },


    ]
  },

]

const router = createRouter({
  routes,
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }
  }
})

export default router
